import {
  revisionRequestMeta,
  RevisionRequestSaved,
} from "../../../../../../lib/object/entity/revision-request";
import { LoginUserInfo } from "../../../../../../store/auth/types";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  CMButton,
  CMButtonBack,
  CMFormInputTextArea,
  ValidationError,
} from "@pscsrvlab/psc-react-components";
import { RevisionRequestItemViewModel } from "../../../../../../lib/object/vm/revision-request-view-model";
import { hasValue, isNullish } from "../../../../../../lib/util/common-util";
import { useNavigate } from "react-router-dom";
import useCustomToast from "../../../../../../hooks/use-custom-toast";
import { useSaveRevisionRequestReplyMutation } from "../../../../../../store/api/generated/stock-request-api";
import {
  Container,
  Flex,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FrameUpperLeftButton } from "../../../../../ui/frame/FrameUpperLeftButton/FrameUpperLeftButton";
import { ProgressStepDocumentRevision } from "../../../../../ui/progress/ProgressStepDocumentRevision/ProgressStepDocumentRevision";
import { FrameUpperRightButton } from "../../../../../ui/frame/FrameUpperRightButton/FrameUpperRightButton";
import { EditEndButton } from "../../../../../ui/button/EditEndButton/EditEndButton";
import { SaveChangesButton } from "../../../../../ui/button/SaveChangesButton/SaveChangesButton";
import { useLeaveEditingPrompt } from "../../../../../../hooks/use-leave-editing-prompt";
import { RevisionRequestCommentList } from "../../../../../model/document/RevisionRequestPage/_components/RevisionRequestCommentList/RevisionRequestCommentList";
import { NewApplicationDocument } from "../../../../../model/document/application/new-application/NewApplicationDocument/NewApplicationDocument";
import { ChangeApplicationDocument } from "../../../../../model/document/application/change-application/ChangeApplicationDocument/ChangeApplicationDocument";
import { AnnualReportDocument } from "../../../../../model/document/report/annual-report/AnnualReportDocument/AnnualReportDocument";
import { TerminationReportDocument } from "../../../../../model/document/report/termination-report/TerminationReportDocument/TerminationReportDocument";
import { DocumentViewModel } from "../../../../../../lib/object/vm/document-view-model";
import { NewApplicationViewModel } from "../../../../../../lib/object/vm/new-application-view-model";
import { ChangeApplicationViewModel } from "../../../../../../lib/object/vm/change-application-view-model";
import { AnnualReportViewModel } from "../../../../../../lib/object/vm/annual-report-view-model";
import { TerminationReportViewModel } from "../../../../../../lib/object/vm/termination-report-view-model";
import { useCreateJsonPatchOperations } from "../../../../../../hooks/use-create-json-patch-operations";
import log from "loglevel";
import { errorMessageOf } from "../../../../../../lib/util/error-util";
import { CommonNextButton } from "../../../../../ui/button/CommonNextButton/CommonNextButton";
import { RevisionMainCommentBalloon } from "../../../../../model/revision/RevisionMainCommentBalloon/RevisionMainCommentBalloon";
import { useAppTranslation } from "../../../../../../hooks/use-app-translation";
import { ConfirmationModal } from "../../../../../ui/modal/ConfirmationModal/ConfirmationModal";
import { useRevisionRequestComment } from "../../../../../../hooks/revision-request/use-revision-request-comment";
import { revisionRequestBodyMeta } from "../../../../../../lib/object/value/revision-request-body";

export type ReviseCreatePageProps<VM extends DocumentViewModel> = {
  loginUserInfo: LoginUserInfo;

  /**
   * URLパラメタの案件ID。
   * 案件画面内にいるかの判定に利用する。
   */
  projectIdParam?: number;

  projectId: number;
  documentId: number;
  revisionRequestId: number;

  vmAndCallback: {
    type: VM["type"];
    vm: VM;
    saveDocument: (
      vm: VM,
      skipOptionalValidations: boolean,
    ) => Promise<
      | { state: "ok"; documentId: number }
      | { state: "error"; errors: ValidationError[] }
      | { state: "unexpectedError" }
    >;
    deleteDocument: () => Promise<
      { state: "ok" } | { state: "unexpectedError" }
    >;
  };
  validationErrors: ValidationError[];
  initialRevisionRequest: RevisionRequestSaved;
};

export const ReviseCreatePage = <VM extends DocumentViewModel>({
  loginUserInfo,

  projectIdParam,

  projectId,
  documentId,
  revisionRequestId,

  vmAndCallback,
  validationErrors,
  initialRevisionRequest,
}: ReviseCreatePageProps<VM>) => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { errorToast } = useCustomToast();

  const { navigateWithoutPrompt } = useLeaveEditingPrompt();
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const [documentValue, setDocumentValue] = useState<VM>(vmAndCallback.vm);
  const handleChangeDocumentValue = useCallback(
    (change: (before: VM) => VM) => {
      setDocumentValue(change);
    },
    [],
  );

  const [revisionRequestReplyBodyVM, setRevisionRequestReplyBodyVM] =
    useState<string>(initialRevisionRequest.reply ?? "");

  const [revisionRequestItemsVM, setRevisionRequestItemsVM] = useState<
    RevisionRequestItemViewModel[]
  >(initialRevisionRequest.items);

  const [selectedCommentPath, setSelectedCommentPath] = useState<string | null>(
    null,
  );
  const handleSelectComment = useCallback((path: string | null) => {
    setSelectedCommentPath(path);
  }, []);

  const urlPathPrefix = useMemo(
    () => (hasValue(projectIdParam) ? `/project/${projectIdParam}` : ""),
    [projectIdParam],
  );
  const backUrlPath = useMemo(() => {
    return `${urlPathPrefix}/document/${documentId}/content`;
  }, [urlPathPrefix, documentId]);

  const handleClickBack = useCallback(() => {
    navigate(backUrlPath);
  }, [navigate, backUrlPath]);

  /**
   * 履歴参照ボタンが押下された際のイベント
   * routerに定義されたURLで履歴参照画面を表示させる
   */
  const handleClickHistoryButton = useCallback(() => {
    const baseURL = window.location.origin;
    window.open(
      baseURL + `/revision-history/${documentId}`,
      '_blank',
      'width=1200,height=600'
    );
  }, [documentId]);

  const [saveRevisionRequestReply] = useSaveRevisionRequestReplyMutation();
  const { createJsonPatchOperations } =
    useCreateJsonPatchOperations(revisionRequestMeta);
  const handleSave = useCallback(
    async (mode: "draft" | "confirm") => {
      const revisionRequestValidationState = revisionRequestMeta.validate(
        {
          ...initialRevisionRequest,
          reply: revisionRequestReplyBodyVM,
          items: revisionRequestItemsVM,
        },
        mode === "draft", // 途中保存の場合、修正依頼（本文やコメント）は、一部バリデーションのみ通す。
      );
      if (revisionRequestValidationState.state === "error") {
        revisionRequestValidationState.errors.forEach((e) => {
          errorToast(e.fullPropertyDisplayName + ": " + e.errorMessage);
        });
        return;
      }
      const validatedRevisionRequest = revisionRequestValidationState.value;

      // 確定時の場合、返信未入力のコメントがないことを確認する。
      if (
        mode === "confirm" &&
        validatedRevisionRequest.items.some((item) =>
          isNullish(item.applicantComment),
        )
      ) {
        errorToast(t("mes.修正返信コメント未入力エラーメッセージ"));
        return;
      }

      const saveDocumentResult = await vmAndCallback.saveDocument(
        documentValue,
        false, // すでに下書き状態ではないため、途中保存だろうが何だろうが、書類は全バリデーションを通す。
      );
      if (saveDocumentResult.state === "error") {
        return;
      }
      if (saveDocumentResult.state === "unexpectedError") {
        errorToast(t("mes.汎用エラーメッセージ"));
        return;
      }
      try {
        const jsonPatchOperations = createJsonPatchOperations(
          initialRevisionRequest,
          validatedRevisionRequest,
        );
        await saveRevisionRequestReply({
          revisionRequestId,
          commonUpdateRequest: { patchOperations: jsonPatchOperations },
        }).unwrap();
      } catch (e) {
        log.error(errorMessageOf(e));
        errorToast(t("mes.汎用エラーメッセージ"));
        return;
      }

      await navigateWithoutPrompt(
        mode === "draft"
          ? backUrlPath
          : `${urlPathPrefix}/document/${documentId}/content/revise/${revisionRequestId}/confirm`,
      );
    },
    [
      initialRevisionRequest,
      revisionRequestReplyBodyVM,
      revisionRequestItemsVM,
      vmAndCallback,
      documentValue,
      navigateWithoutPrompt,
      backUrlPath,
      urlPathPrefix,
      documentId,
      revisionRequestId,
      errorToast,
      t,
      createJsonPatchOperations,
      saveRevisionRequestReply,
    ],
  );
  const handleClickEditEnd = useCallback(() => {
    navigate(backUrlPath);
  }, [backUrlPath, navigate]);

  const { ctx: revisionRequestCommentContext } = useRevisionRequestComment(
    loginUserInfo,
    "applicant_editable",
    revisionRequestItemsVM,
    handleSelectComment,
    setRevisionRequestItemsVM,
  );

  const scrollableRef = useRef(null);

  return (
    <>
      <VStack
        className={"ReviseCreatePage"}
        alignSelf={"stretch"}
        alignItems={"stretch"}
        overflow={"hidden"}
        spacing={0}
      >
        <Flex
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          borderBottomWidth={"1px"}
          borderColor={"gray.300"}
          pb={"12px"}
        >
          <FrameUpperLeftButton>
            <CMButtonBack
              labelBack={t("btn.書類内容に戻るボタン")}
              onClick={handleClickBack}
            />
          </FrameUpperLeftButton>
          <ProgressStepDocumentRevision currentStep={0} />
          <FrameUpperRightButton pl={"20px"}>
            <HStack w={"max-content"}>
              <SaveChangesButton onClick={onOpenModal} />
              <EditEndButton onClick={handleClickEditEnd} />
            </HStack>
          </FrameUpperRightButton>
        </Flex>
        <HStack flex={"1 1 auto"} minH={0} spacing={0} alignItems={"stretch"}>
          <VStack
            flex={1}
            pb={"60px"}
            overflow={"auto"}
            spacing={0}
            ref={scrollableRef}
          >
            <HStack
              pt={"10px"}
              pl={"10px"}
              mr={"10px"}
              alignSelf={"stretch"}
              justifyContent={"space-between"}
              width="100%"
            >
              <RevisionMainCommentBalloon
                mode={"request"}
                revisionRequestId={revisionRequestId}
              />

              {/* 履歴参照ボタン */}
              <FrameUpperRightButton>
                <CMButton
                  size={"sm"}
                  label={t("btn.修正依頼履歴参照ボタン")}
                  onClick={handleClickHistoryButton}
                />
              </FrameUpperRightButton>
            </HStack>

            <Container minW={"500px"} maxW={"720px"}>
              {vmAndCallback.type === "new_application" &&
                hasValue(loginUserInfo) && (
                  <NewApplicationDocument
                    loginUserInfo={loginUserInfo}
                    editMode={"editable"}
                    value={documentValue as NewApplicationViewModel}
                    onChange={
                      handleChangeDocumentValue as unknown as (
                        change: (
                          before: NewApplicationViewModel,
                        ) => NewApplicationViewModel,
                      ) => void
                    }
                    revisionMode={"applicant_editable"}
                    revisionRequestItems={revisionRequestItemsVM}
                    onSelectComment={handleSelectComment}
                    onChangeRevisionRequestItems={setRevisionRequestItemsVM}
                    validationErrors={validationErrors}
                    scrollableRef={scrollableRef}
                    scrollOffset={60}
                  />
                )}
              {vmAndCallback.type === "change_application" &&
                hasValue(loginUserInfo) && (
                  <ChangeApplicationDocument
                    loginUserInfo={loginUserInfo}
                    editMode={"editable"}
                    value={documentValue as ChangeApplicationViewModel}
                    onChange={
                      handleChangeDocumentValue as unknown as (
                        change: (
                          before: ChangeApplicationViewModel,
                        ) => ChangeApplicationViewModel,
                      ) => void
                    }
                    revisionMode={"applicant_editable"}
                    revisionRequestItems={revisionRequestItemsVM}
                    onSelectComment={handleSelectComment}
                    onChangeRevisionRequestItems={setRevisionRequestItemsVM}
                    validationErrors={validationErrors}
                    scrollableRef={scrollableRef}
                    scrollOffset={60}
                  />
                )}
              {vmAndCallback.type === "annual_report" &&
                hasValue(loginUserInfo) && (
                  <AnnualReportDocument
                    loginUserInfo={loginUserInfo}
                    editMode={"editable"}
                    value={documentValue as AnnualReportViewModel}
                    onChange={
                      handleChangeDocumentValue as unknown as (
                        change: (
                          before: AnnualReportViewModel,
                        ) => AnnualReportViewModel,
                      ) => void
                    }
                    revisionMode={"applicant_editable"}
                    revisionRequestItems={revisionRequestItemsVM}
                    onSelectComment={handleSelectComment}
                    onChangeRevisionRequestItems={setRevisionRequestItemsVM}
                    validationErrors={validationErrors}
                  />
                )}
              {vmAndCallback.type === "termination_report" &&
                hasValue(loginUserInfo) &&
                hasValue(projectId) && (
                  <TerminationReportDocument
                    loginUserInfo={loginUserInfo}
                    editMode={"editable"}
                    value={documentValue as TerminationReportViewModel}
                    onChange={
                      handleChangeDocumentValue as unknown as (
                        change: (
                          before: TerminationReportViewModel,
                        ) => TerminationReportViewModel,
                      ) => void
                    }
                    revisionMode={"applicant_editable"}
                    revisionRequestItems={revisionRequestItemsVM}
                    onSelectComment={handleSelectComment}
                    onChangeRevisionRequestItems={setRevisionRequestItemsVM}
                    validationErrors={validationErrors}
                  />
                )}
              <VStack alignItems={"flex-start"} pt={"20px"} pb={"20px"}>
                <HStack alignItems={"baseline"} pl={"4px"}>
                  <Text fontSize={"lg"} color={"gray.900"} fontWeight={"bold"}>
                    {t("lbl.返信本文")}
                  </Text>
                  <Text fontSize={"11px"} color={"gray.600"}>
                    {t("gdc.返信本文補足")}
                  </Text>
                </HStack>
                <CMFormInputTextArea
                  noHeader={true}
                  valueObjectMeta={revisionRequestBodyMeta}
                  value={revisionRequestReplyBodyVM}
                  onChange={setRevisionRequestReplyBodyVM}
                  minH={"120px"}
                  sx={{ alignSelf: "stretch" }}
                />
              </VStack>
            </Container>

            <HStack>
              <CommonNextButton
                label={t("btn.確認へ進むボタン")}
                onClick={() => handleSave("confirm")}
              />
            </HStack>
          </VStack>
          <RevisionRequestCommentList
            revisionMode={"applicant_editable"}
            revisionRequestItems={revisionRequestItemsVM}
            onSaveReply={revisionRequestCommentContext.onSaveReply}
            selectedCommentPath={selectedCommentPath}
            scrollableRef={scrollableRef}
            scrollOffset={60}
          />
        </HStack>
      </VStack>
      {/* 変更を保存する押下時の確認モーダル */}
      <ConfirmationModal
        isOpen={isOpenModal}
        message={t("mes.変更保存確認メッセージ")}
        onSubmit={() => handleSave("draft")}
        onCancel={onCloseModal}
      />
    </>
  );
};
